<template>
  <div class="row justify-content-center px-8 px-lg-10">
    <div class="col-xl-12 col-xxl-7 justify-content-center">
      <!--begin::Form Wizard-->
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form class="form">
        <!--begin::Step 1-->
        <div data-wizard-type="step-content" data-wizard-state="current">
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('institutions.institutions_name') }}</label>
                <validation-provider rules="required" :name="$t('institutions.institutions_name')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <input
                    class="form-control"
                    :class="classes"
                    type="text"
                    v-model="formData.name"
                  />
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.country') }}</label>
                <validation-provider rules="required" :name="$t('commons.country')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <multiselect
                      v-model="selectedCountry"
                      :options="countries"
                      :internal-search="true"
                      :allow-empty="false"
                      open-direction="bottom"
                      @input="onChangeCountry"
                      deselect-label=''
                      select-label=''
                      selected-label=''
                      track-by='code'
                      label='code'
                      :placeholder="$t('commons.choose')"
                      :class="classes"
                  >
                    <template slot='singleLabel' slot-scope='{ option }'>{{ option.code.toUpperCase() }}</template>
                    <template slot='option' slot-scope='{ option }'>{{ option.code.toUpperCase() }}</template>
                    <template slot='noOptions'>{{ 'List is empty' }}</template>
                  </multiselect>
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('institutions.type') }}</label>
                <validation-provider rules="required" :name="$t('institutions.type')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <multiselect
                      v-model="selectedType"
                      :options="institutionTypes"
                      :searchable="false"
                      :allow-empty="false"
                      open-direction="bottom"
                      @input="onChangeType"
                      deselect-label=''
                      select-label=''
                      selected-label=''
                      track-by='label'
                      label='label'
                      :placeholder="$t('commons.choose')"
                      :class="classes"
                  >
                    <template slot='singleLabel' slot-scope='{ option }'>{{ $t(`service.${option.label}`) }}</template>
                    <template slot='option' slot-scope='{ option }'>{{ $t(`service.${option.label}`) }}</template>
                    <template slot='noOptions'>{{ 'List is empty' }}</template>
                  </multiselect>
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.mobile_phone') }}</label>
                <validation-provider rules="required" :name="$t('commons.mobile_phone')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <div class="input-group" :class="classes">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="la la-phone"></i
                      ></span>
                    </div>
                    <input
                      type="number"
                      value=""
                      name="phone"
                      id="phoneValid"
                      minlength="10"
                      placeholder="Phone Number - 533xxxxxxx"
                      required="required"
                      aria-describedby="basic-addon1"
                      class="form-control"
                      :class="classes"
                      v-model="formData.phone"
                    />
                  </div>
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.e_mail') }}</label>
                <validation-provider rules="required" :name="$t('commons.e_mail')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <div class="input-group" :class="classes">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="la la-at"></i
                      ></span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      :class="classes"
                      name="email"
                      placeholder="Email"
                      v-model="formData.email"
                    />
                  </div>
                 <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.password') }}</label>
                <validation-provider rules="required" :name="$t('commons.password')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <input
                    class="form-control"
                    :class="classes"
                    type="text"
                    v-model="formData.password"
                  />
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.choosen_status') }}</label>
                <validation-provider rules="required" :name="$t('commons.status')" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                  <multiselect
                    v-model="selectedStatus"
                    :options="allStatus"
                    :searchable="false"
                    :allow-empty="false"
                    open-direction="bottom"
                    @input="onChangeStatus"
                    deselect-label=''
                    select-label=''
                    selected-label=''
                    track-by='name'
                    label='name'
                    :placeholder="$t('commons.choosen_status')"
                    :class="classes"
                  >
                      <template slot='singleLabel' slot-scope='{ option }'>{{ option.name }}</template>
                      <template slot='option' slot-scope='{ option }'>{{ option.name }}</template>
                      <template slot='noOptions'>{{ 'List is empty' }}</template>
                  </multiselect>
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div v-else class="col-lg-9 col-xl-9">
                  <Skeleton height="38px" />
                  <span class="error__message"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--begin::Actions-->
        <div class="d-flex justify-content-end border-top mt-5 pt-10">
          <form-submit-btn
            :btnClass="'btn btn-success font-weight-bolder text-uppercase px-9 py-4'"
            :isSubmitting="isSubmitting"
            @click="handleSubmit(updateInstitution)"
            :text="$t('commons.submit')"
          />
        </div>
        <!--end::Actions-->
      </form>
      </ValidationObserver>
      <!--end::Form Wizard-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import i18n from '@/config/_i18n'
import { Skeleton } from 'vue-loading-skeleton';

const all_status = [
  { value: 1, name: i18n.t("institutions.active_status") },
  { value: 0, name: i18n.t("institutions.passive_status") }
]

export default {
  name: "UpdateInstitution",
  data() {
    return {
      formData: {
        name: "",
        country_id: "",
        type: "",
        phone: "",
        email: "",
        password: "",
        status: ""
      },
      isSubmitting: false,
      selectedCountry: null,
      selectedType: null,
      allStatus: all_status,
      selectedStatus: ""
    };
  },
  components: {
    Multiselect,
    Skeleton
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.institutions.isLoading,
      countries: (state) => state.institutions.countries,
      institutionTypes: (state) => state.institutions.institutionTypes,
      editDataForm: (state) => state.institutions.editDataForm,
    }),
    institutionID: function () {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions("institutions", [
      "GET_INSTITUTIONS_DATA",
      "UPDATE_INSTITUTIONS",
    ]),
    updateInstitution() {
      this.UPDATE_INSTITUTIONS({
        id: this.institutionID,
        form: this.formData
      })
    },
    onChangeCountry() {
      console.log(this.selectedCountry)
      this.formData.country_id = this.selectedCountry.id
    },
    onChangeType() {
      console.log(this.selectedType)
      this.formData.type = this.selectedType.value
    },
    onChangeStatus() {
      console.log(this.selectedStatus)
      this.formData.status = this.selectedStatus.value
    }
  },
  created() {
    this.GET_INSTITUTIONS_DATA({ type: "edit", id: this.institutionID }).then(
      () => {
        // console.log("edit: ",this.editDataForm)
        const {
          name,
          country_id,
          type,
          phone,
          email,
          status
        } = this.editDataForm;

        this.formData = {
          ...this.formData,
          name,
          country_id,
          type,
          phone,
          email,
        };
        if(this.$store.state.institutions?.countries?.length > 0) {
          this.selectedCountry = this.$store.state.institutions.countries.find(data => String(data.id) === String(country_id));
        }
        if(this.$store.state.institutions?.institutionTypes?.length > 0) {
          this.selectedType = this.$store.state.institutions.institutionTypes.find(data => String(data.value) === String(type));
        }
        this.selectedStatus = all_status.find(data => String(data.value) === String(status));
      }
    );
  }
};
</script>
